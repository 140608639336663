.modal-filter {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .input-name,
  select {
    color: #a8a8b3;
    width: 130px;
    padding: 4px;
    border-radius: 5px;
    background-color: white;
  }

  .filter-box {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
}

.rangeslider {
  display: flex;
  align-items: center;
  position: relative;
  input {
    position: absolute;
  }

  .container-ranges {
    display: flex;
    justify-content: center;
    width: 110px; //deve ser o um pouco maior doq width do input abaixo para melhor UX, se mudar um --> mude o outro para melhorar visualmente

    input[type="range"] {
      width: 100px;
      height: 0px;
    }

    // serve para remover a estilização padrão do input range
    input[type="range"],
    input[type="range"]::-webkit-slider-runnable-track,
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 2px; // defina a altura da barrinha amarela, caso queria mais grossinha aumentar o valor.
      background: #e2ab49;
    }

    //estilização do botãozinho
    input[type="range"]::-webkit-slider-thumb {
      position: relative;
      height: 15px;
      width: 15px;
      margin-top: -7px;
      background: repeating-radial-gradient(
          circle at 50% 50%,
          rgba(200, 200, 200, 0.2) 0%,
          rgba(200, 200, 200, 0.2) 2%,
          transparent 2%,
          transparent 3%,
          rgba(200, 200, 200, 0.2) 3%,
          transparent 3%
        ),
        conic-gradient(
          white 0%,
          silver 10%,
          white 35%,
          silver 45%,
          white 60%,
          silver 70%,
          white 80%,
          silver 95%,
          white 100%
        );
      box-shadow: 0 0px 20px #0005, 0 -0px 20px #fff2;
      outline: 1px solid #fff;
      outline-offset: -2px;
      border-radius: 25px;
      z-index: 1;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
    input[type="range"]::-webkit-slider-thumb:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    // torna os dois botões clicáveis
    input[type="range"]:nth-child(1)::-webkit-slider-thumb {
      z-index: 2;
    }

    input[type="range"]:nth-child(2)::-webkit-slider-thumb {
      z-index: 2;
    }

    /* Estilização específica do Firefox */
    /* Estilização específica do Firefox */
    @-moz-document url-prefix() {
      input[type="range"] {
        width: 100px;
        height: 0px;
      }

      input[type="range"]::-moz-range-track,
      input[type="range"]::-moz-range-thumb {
        -webkit-appearance: none;
        background: none;
      }

      input[type="range"]::-moz-range-track {
        height: 2px;
        /* Defina a altura da barra amarela */
        background: #e2ab49;
      }

      input[type="range"]::-moz-range-thumb {
        position: relative;
        height: 15px;
        width: 15px;
        margin-top: -7px;
        background: repeating-radial-gradient(
            circle at 50% 50%,
            rgba(200, 200, 200, 0.2) 0%,
            rgba(200, 200, 200, 0.2) 2%,
            transparent 2%,
            transparent 3%,
            rgba(200, 200, 200, 0.2) 3%,
            transparent 3%
          ),
          conic-gradient(
            white 0%,
            silver 10%,
            white 35%,
            silver 45%,
            white 60%,
            silver 70%,
            white 80%,
            silver 95%,
            white 100%
          );
        box-shadow: 0 0px 20px #0005, 0 -0px 20px #fff2;
        outline: 1px solid #fff;
        outline-offset: -2px;
        border-radius: 25px;
        z-index: 1;
      }

      input[type="range"]:nth-child(1)::-moz-range-thumb {
        transform: translateY(10px);
        clip-path: polygon(50% 0%, 0 50%, 100% 50%);
      }

      input[type="range"]:nth-child(2)::-moz-range-thumb {
        transform: translateY(10px);
        clip-path: polygon(50% 0%, 0 50%, 100% 50%);
      }
    }
  }
}
