:root {
  /* UI & surface colors */

  --bg-primary: #ffffff;
  --bg-secondary: #8c8c8c;

  --text-primary: #fff;
  --text-secondary: #c0bfc4;

  /* Brand colors */
  --basket_orange-50: #fff6ed;
  --basket_orange-100: #ffead4;
  --basket_orange-200: #ffd1a8;
  --basket_orange-300: #ffb070;
  --basket_orange-400: #4251b3;
  --basket_orange-500: #2a3cad;
  --basket_orange-700: #162bb6;
  --basket_orange-800: #111e77;
  --basket_orange-900: #0c1866;
  --basket_orange-950: #030933;

  --basket_blue-50: #e9f7ff;
  --basket_blue-100: #ceedff;
  --basket_blue-200: #a7e1ff;
  --basket_blue-300: #6bd2ff;
  --basket_blue-400: #26b6ff;
  --basket_blue-500: #008bff;
  --basket_blue-600: #0061ff;
  --basket_blue-700: #0046ff;
  --basket_blue-800: #003be6;
  --basket_blue-900: #001f4b;
  --basket_blue-950: #000c24;
}

.switch-button {
  background-color: var(--basket_blue-900);
  border-radius: 15px;
  overflow: hidden;
  width: 250px;
  height: 50px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding-right: 120px;
  position: relative;

  &:before {
    content: attr(data-on);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  & input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked ~ .switch-button-label:before {
      transform: translateX(130px);
      transition: transform 300ms linear;
    }

    & ~ .switch-button-label {
      position: relative;
      display: flex;
      align-items: center;
      padding: 5px;
      user-select: none;
      pointer-events: none;
      height: 50px;

      &:before {
        content: "";
        background: var(--basket_orange-500);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 15px;
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
      }
    }
  }
}
