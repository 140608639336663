:root {
  /* UI & surface colors */

  --bg-primary: #ffffff;
  --bg-secondary: #8c8c8c;

  --text-primary: #fff;
  --text-secondary: #c0bfc4;

  /* Brand colors */
  --basket_orange-50: #fff6ed;
  --basket_orange-100: #ffead4;
  --basket_orange-200: #ffd1a8;
  --basket_orange-300: #ffb070;
  --basket_orange-400: #ff8337;
  --basket_orange-500: #ff6617;
  --basket_orange-600: #f04606;
  --basket_orange-700: #c73207;
  --basket_orange-800: #9e280e;
  --zombie_blue-900: #005885;
  --basket_orange-950: #450f05;

  --zombie-yellow-400: #febf02;

  --basket_blue-50: #e9f7ff;
  --basket_blue-100: #ceedff;
  --basket_blue-200: #a7e1ff;
  --basket_blue-300: #6bd2ff;
  --basket_blue-400: #26b6ff;
  --basket_blue-500: #008bff;
  --basket_blue-600: #0061ff;
  --basket_blue-700: #0046ff;
  --basket_blue-800: #003be6;
  --zombie_brown-900: #56361f;
  --zombie_brown-950: #3e2310;

  --bg-pri: #07080e;
  --bg-sec: #202123;

  --text-pri: #fff;
  --text-sec: #92929f;
}

.tooltip {
  position: relative;
}

.tooltip > .button {
  visibility: hidden;
  width: 180px;
  background-color: var(--zombie_brown-950);
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  border: 2px solid var(--basket_blue-950);
  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 50px;
  left: -20%;
  z-index: 101;
}

.tooltip:hover .button {
  visibility: visible;
}

.tooltip .button::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--basket_blue-950) transparent transparent transparent;
}

.tooltip-left-side {
  min-width: 180px;
  white-space: nowrap;
  background-color: var(--zombie_brown-950);
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  border: 2px solid var(--basket_blue-950);
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip > .tooltip-left-side {
  visibility: hidden;
  width: max-content;
  background-color: var(--zombie_brown-950);
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  border: 2px solid var(--basket_blue-950);
  /* Position the tooltip text - see examples below! */
  position: absolute;

  z-index: 101;
}

.tooltip:hover .tooltip-left-side {
  visibility: visible;
}

.tooltip-left-side::after {
  content: " ";
  position: absolute;
  top: 30%;
  right: 100%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent var(--basket_blue-950) transparent transparent;
}
