.circle-progress {
  position: relative;
}

.circle-progress .outer {
  position: relative;
  width: 80px;
  height: 80px;
  padding: 20px;
  box-shadow: 2px 2px 2px -1px #00000030, -2px -2px 5px -1px #000000c3;
  border-radius: 50%;
  background-color: #ffffff5c;
}

.text_shadow {
  text-shadow: 3px 3px 3px black;
}

.circle-progress .inner {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 4px 4px 6px -1px #00000030,
    inset -4px -4px 6px -1px #ffffffc3, 0.5px 0.5px 0px #00000030,
    0px 12px 10px -10px #00000011;
  border-radius: 50%;
}

.circle-progress circle {
  stroke-width: 20px;
  stroke: url(#GradientColor);
  fill: none;
  stroke-width: 20px;
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
}

.circle-progress .animation-on {
  animation: fill-circle 80s infinite;
}

.circle-progress svg {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes fill-circle {
  0% {
    stroke-dashoffset: 450;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
