:root {
  /* UI & surface colors */

  --bg-primary: #ffffff;
  --bg-secondary: #8c8c8c;

  --text-primary: #fff;
  --text-secondary: #c0bfc4;

  /* Brand colors */
  --basket_orange-50: #fff6ed;
  --basket_orange-100: #ffead4;
  --basket_orange-200: #ffd1a8;
  --basket_orange-300: #ffb070;
  --basket_orange-400: #4251b3;
  --basket_orange-500: #2a3cad;
  --basket_orange-700: #162bb6;
  --basket_orange-800: #111e77;
  --basket_orange-900: #0c1866;
  --basket_orange-950: #030933;

  --basket_blue-50: #e9f7ff;
  --basket_blue-100: #ceedff;
  --basket_blue-200: #a7e1ff;
  --basket_blue-300: #6bd2ff;
  --basket_blue-400: #26b6ff;
  --basket_blue-500: #008bff;
  --basket_blue-600: #0061ff;
  --basket_blue-700: #0046ff;
  --basket_blue-800: #003be6;
  --basket_blue-900: #001f4b;
  --basket_blue-950: #000c24;
}

.clear {
  clear: both;
}

.checkBox {
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 0px 2px #fff;
  box-shadow: 0px 0px 0px 2px #fff;
}

.checkBox div {
  width: 60px;
  height: 60px;
  background-color: #fff;
  top: -52px;
  left: -52px;
  position: absolute;
  -webkit-transform: rotateZ(45deg);
  -ms-transform: rotate(45deg);
  transform: rotateZ(45deg);
  z-index: 30;
}

.checkBox input[type="checkbox"]:checked + div {
  left: -10px;
  top: -10px;
}

.checkBox input[type="checkbox"] {
  position: absolute;
  left: 50px;
  visibility: hidden;
}

.transition {
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
}

.rocker {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.switch-left,
.switch-right {
  cursor: pointer;
  position: absolute;
  color: #fff;
}

input[type="checkbox"] {
  appearance: none;
  height: 40px;
  width: 70px;
  background-color: #27272c;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  display: block;
  height: 40px;
  width: 35px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: calc(1.9em / 2);
  background-color: var(--basket_orange-500);
  transition: 0.3s ease;
  border-radius: 10px;
}

input[type="checkbox"]:checked::before {
  background-color: var(--basket_orange-500);
  left: calc(100% - (1.9em / 2));
  border-radius: 10px;
}
