:root {
  /* the font-family for all text */
  --nefty-font-family: "Montserrat" !important;
  /* the default font size (best kept as is) */
  --nefty-font-size: 16px !important;
  /* the smaller font size (best kept as is) */
  --nefty-font-size--small: 14px !important;

  /* the minimum and maximum width of the cards (responsive)  */
  --nefty-card-size-min: 250px !important;
  --nefty-card-size-max: 1fr !important;

  /*  the space between cards */
  --nefty-cards-gap: 28px !important;

  /* border radius for all roundings of inputs, buttons, images */
  --nefty-radius: 24px !important;
  --nefty-radius-image: 18px !important;
  --nefty-radius-small: 12px !important;

  /* border color for buttons, inputs */
  --nefty-border: #f0a591 !important;
  /* border color for the cards (if none use same color as card background) */
  --nefty-border-card: #f0a591 !important;
  /* border thickness */
  --nefty-border-size: 2px !important;

  /* color primary text */
  --nefty-color: #fcfcfd !important;
  /* colors for non primary text */
  --nefty-color-secondary: #d9dbe2 !important;

  /* buy button in the card (highlight) */
  /* background: linear-gradient(183.04deg,#9014d8 7.37%,#c96af4 83.62%);*/
  --nefty-btn-primary: #fcfcfd !important;
  --nefty-btn-primary-bg: #17205a !important;
  --nefty-btn-primary-border: #f0a591 !important;
  --nefty-btn-primary--active: #f0a591 !important;
  --nefty-btn-primary-bg--active: #f0a591 !important;
  --nefty-btn-primary-border--active: #f0a591 !important;

  /* styling of pagination buttons and small reset button (will appear once you start filtering) */
  --nefty-btn-secondary: #fcfcfd !important;
  --nefty-btn-secondary-bg: #17205a !important;
  --nefty-btn-secondary-border: #f0a591 !important;
  --nefty-btn-secondary--active: #f0a591 !important;
  --nefty-btn-secondary-bg--active: #f0a591 !important;
  --nefty-btn-secondary-border--active: #f0a591 !important;

  /* the filter button on mobile design */
  --nefty-btn-tertiary: #fcfcfd !important;
  --nefty-btn-tertiary-bg: #17205a !important;
  --nefty-btn-tertiary-border: #f0a591 !important;
  --nefty-btn-tertiary--active: #f0a591 !important;
  --nefty-btn-tertiary-bg--active: #f0a591 !important;
  --nefty-btn-tertiary-border--active: #f0a591 !important;

  /* backgrounds */
  /* list background in filters (schemas) */
  --nefty-bg-list-item: #17205a !important;
  /* card mint background */
  --nefty-bg-mint: #17205a !important;
  /* all inputs (filters) */
  --nefty-bg-inputs: rgb(1, 26, 84) !important;
  /* card background */
  --nefty-bg-card: rgb(1, 26, 84, 0.3) !important;
  /* loading state */
  --nefty-bg-loading: rgba(255, 255, 255, 0.05) !important;
  /* fallback background color for images (best kept as is) */
  --nefty-bg-image: rgba(255, 255, 255, 0.05) !important;
}

neftyblocks-market {
  width: 80vw !important;
}

::part(container) {
  height: auto !important;
}
