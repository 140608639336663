:root {
  /* UI & surface colors */

  --bg-pri: #07080e;
  --bg-sec: #202123;

  --text-pri: #fff;
  --text-sec: #92929f;
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
  transition: all 0.2s ease-in-out;
}

.flip-card-drop {
  width: 210px;
  height: 255px;
}

.flip-card-pack {
  width: 230px;
  height: 320px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.623);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  transform: rotateX(0deg);
  backface-visibility: hidden;
  border-radius: 12px;
  /* cursor: pointer; */
  background-color: var(--bg-sec);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card:focus .flip-card-inner {
  transform: rotateY(0deg) !important;
}

/* @media (min-width: 768px) {
  .flip-card:hover {
    transform: scale(1.1);
  }
} */

.flip-card-back {
  background-color: var(--bg-sec);
  color: white;
  transform: rotateY(180deg);
}

.input-flip {
  display: none;
}

/* :checked + .flip-card-inner {
  transform: rotateY(180deg);
} */
