.main {
  @apply h-[153px] w-[110px] xs:h-[181px] xs:w-[130px] md:h-[200px] md:w-[150px];
}

.card {
  background: #202123;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  @apply h-[153px] w-[110px] rounded-xl text-center xs:h-[181px] xs:w-[130px] md:h-[200px] md:w-[150px] md:rounded-2xl;
}
